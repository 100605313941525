
<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col border">
          <b-form @submit="onClickBtn">
            <label for="appKey">App Key</label>

            <b-form-input required v-model="appKey" placeholder="Enter app_key"></b-form-input>
            <label for="appSecret">App Secret</label>
            <b-form-input required v-model="appSecret" placeholder="Enter app_secret"></b-form-input>
            <label for="cid">CID</label>

            <b-form-input required v-model="cid" placeholder="Enter cid"></b-form-input>

            <b-button type="submit" variant="primary">Submit</b-button>
            <div v-if="loading" class="loader" />
          </b-form>
           <b-alert style="height:400; overflow: auto;" v-if="raw" show variant="danger">
          {{ raw }}
           </b-alert>

        </div>
        <div class="col border" style="height:700px; overflow: auto;">
          <ListView
            depth="0"
            :loading="loading"
            :handler="handler"
            :selected="selected"
            :data="data"
          ></ListView>
        </div>
        <div class="col">
          <textarea disabled :value="dataOutput" class="form-control h-50" rows="3"></textarea>
          <textarea disabled :value="dataOutput2" class="form-control h-50" rows="3"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ListView from "./components/ListView.vue";
const R = require("ramda");

const TopClient = require("topsdk");

const parser = (parent, data) =>
  R.compose(
    R.sortBy(R.propEq("must", false)),
    R.map((child) => ({
      id: R.toString(R.prop("pid")(child)),
      multi: R.prop("multi")(child),
      must: R.prop("must")(child),
      name: R.prop("name")(child),
      pid: R.toString(parent.id),
      is_parent: !!R.compose(
        R.length,
        R.path(["prop_values", "prop_value"])
      )(child),
      childrend: R.compose(
        R.map(
          R.applySpec({
            is_parent: R.compose(R.defaultTo(false), R.prop("is_parent")),
            id: R.compose(R.toString, R.prop("vid")),
            childrend: R.always([]),
            multi: R.always(R.prop("multi")(child)),
            name: R.prop("name"),
            parent_name: R.always(R.compose(R.toString, R.prop("name"))(child)),
            pid: R.always(R.compose(R.toString, R.prop("pid"))(child)),
          })
        ),

        R.defaultTo([]),
        R.path(["prop_values", "prop_value"])
      )(child),
    })),
    R.defaultTo([]),
    // R.filter(R.propEq("must", true)),
    R.path(["itemprops_get_response", "item_props", "item_prop"])
  )(data);

export default {
  name: "App",
  components: {
    ListView,
  },
  computed: {
    dataOutput() {
      const keys = Object.keys(this.selected);
      let result = "";

      keys.forEach((key) => {
        const array = this.selected[key];
        array.forEach((info) => {
          result = result.concat(`${info.pid}:${info.id};`);
        });
      });

      return result;
    },

    dataOutput2() {
      const keys = Object.keys(this.selected);
      let result = "";

      keys.forEach((key) => {
        const array = this.selected[key];
        array.forEach((info) => {
          result = result.concat(
            `{ "p_name": ${info.parent_name},"p_id": "${info.pid}", "v_id": "${info.id}", "v_name": "${info.name}" },\n`
          );
        });
      });

      return result;
    },
  },
  asyncComputed: {
    async someCalculation() {},
  },
  methods: {
    async handler(selected, node) {
      const checked = R.includes(node, selected);

      if (checked && node.is_parent) {
        const client = new TopClient(this.appKey, this.appSecret, {
          endpoint:
            "https://taobao.longdhit.com/router/rest",
          useValidators: false,
          rawResponse: true,
        });
        this.loading = true;
        const data = await client.execute("taobao.itemprops.get", {
          cid: this.cid,
          child_path: `${node.pid}:${node.id}`,
        });
        this.loading = false;
        const res = parser(node, data);
        node.childrend = res;
      }
    },
    async onClickBtn(event) {
      event.preventDefault();
      const client = new TopClient(this.appKey, this.appSecret, {
        endpoint:
          "https://taobao.longdhit.com/router/rest",
        useValidators: false,
        rawResponse: true,
      });

      (this.data = ""), (this.selected = []), (this.raw = "");

      this.loading = true;
      let data = [];

      try {
        data = await client.execute("taobao.itemprops.get", {
          cid: this.cid,
        });

        const parsedData = parser({ id: this.cid }, data);
        if (parsedData.length< 1) {
          this.raw = data;
        }
        const skeletonSelected = {};
        for (const prop of parsedData) {
          skeletonSelected[prop.pid] = [];
        }
        this.selected = skeletonSelected;
        this.data = parsedData;
      } catch (error) {
        console.log(error);
        this.raw = "Server error. Please try again!";
      } finally {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      loading: false,
      cid: "50008406",
      appSecret: "",
      appKey: "",
      data: "",
      selected: [],
      raw: "",
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid blue;
  border-right: 16px solid green;
  border-bottom: 16px solid red;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
