<template>
  <div class="tree-menu" v-if="data">
    <div :class="indent" v-for="item in data" :key="item.id" :name="item.id">
      <b>
        Name: {{ item.name }} | Pid: {{ item.id }}
        <span
          v-if="item.must"
          class="text-danger"
        >required</span>
      </b>
      <ArrayPaging :item="item" :depth="depth+1" :handler="handler" :selected="selected"></ArrayPaging>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "selected", "handler", "loading", "depth"],
  components: {
    ArrayPaging: () => import("./ArrayPaging"),
  },
  name: "ListView",
  computed: {
    indent() {
      return `pl-${this.depth}`;
    },
  },
};
</script>